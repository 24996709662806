.w-pc-global-header-screen{
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}
.w-pc-global-header-screen.wrapper{
    position: relative;
}

.w-pc-global-header-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-global-header-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.w-pc-global-header-screen.wrapper>.background>.frame>img {
    width: 110%;
}

.w-pc-global-header-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.498);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-global-header-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    padding-inline: 3vw;
}

.w-pc-global-header-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-pc-global-header-screen.wrapper>.content.display>.text-wrapper{
    box-sizing: border-box;
    width: 55vw;
    padding-left: 3vw;
    color: #fff;
}

.w-pc-global-header-screen.wrapper>.content.display>.text-wrapper>h1{
    font-size: 5.7vw;
    line-height: 120%;
    font-family: 'CustomPoppinsBold';
}
.w-pc-global-header-screen.wrapper>.content.display>.text-wrapper>p {
    text-align: justify;
    font-size: 1.6vw;
    margin-block: 0.5vw;
    line-height: 150%;
    width: 69%;
}


/* mobile */
.w-mob-global-header-screen {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-mob-global-header-screen.wrapper {
    position: relative;
}

.w-mob-global-header-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-global-header-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.w-mob-global-header-screen.wrapper>.background>.frame>img {
    height: 100%;
}

.w-mob-global-header-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.498);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-global-header-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    padding-inline: 3vw;
}

.w-mob-global-header-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-mob-global-header-screen.wrapper>.content.display>.text-wrapper {
    box-sizing: border-box;
    width: 99%;
    padding-left: 3vw;
    color: #fff;
}

.w-mob-global-header-screen.wrapper>.content.display>.text-wrapper>h1 {
    font-size: 13vw;
    line-height: 120%;
    font-family: 'CustomPoppinsBold';
    margin-bottom: 1vh;
}

.w-mob-global-header-screen.wrapper>.content.display>.text-wrapper>p {
    text-align: justify;
    font-size: 3.7vw;
    margin-block: 3vw;
    line-height: 125%;
    width: 70%;
}


/* mobile tab */
.w-tab-global-header-screen {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-tab-global-header-screen.wrapper {
    position: relative;
}

.w-tab-global-header-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-tab-global-header-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.w-tab-global-header-screen.wrapper>.background>.frame>img {
    width: 130%;
}

.w-tab-global-header-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.498);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-tab-global-header-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    padding-inline: 3vw;
}

.w-tab-global-header-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-tab-global-header-screen.wrapper>.content.display>.text-wrapper {
    box-sizing: border-box;
    width: 90vw;
    padding-left: 2.5vw;
    color: #fff;
}

.w-tab-global-header-screen.wrapper>.content.display>.text-wrapper>h1 {
    font-size: 5.7vh;
    line-height: 120%;
    font-family: 'CustomPoppinsBold';
}

.w-tab-global-header-screen.wrapper>.content.display>.text-wrapper>p {
    text-align: justify;
    font-size: 1.6vw;
    margin-block: 0.5vw;
    line-height: 150%;
    width: 69%;
}


/* nest device*/
.w-nest.w-tab-global-header-screen {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-nest.w-tab-global-header-screen.wrapper {
    position: relative;
}

.w-nest.w-tab-global-header-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-nest.w-tab-global-header-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.w-nest.w-tab-global-header-screen.wrapper>.background>.frame>img {
    width: 130%;
}

.w-nest.w-tab-global-header-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.498);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-nest.w-tab-global-header-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    padding-inline: 3vw;
}

.w-nest.w-tab-global-header-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-nest.w-tab-global-header-screen.wrapper>.content.display>.text-wrapper {
    box-sizing: border-box;
    width: 90vw;
    padding-left: 2.5vw;
    color: #fff;
}

.w-nest.w-tab-global-header-screen.wrapper>.content.display>.text-wrapper>h1 {
    font-size: 5.7vw;
    line-height: 120%;
    font-family: 'CustomPoppinsBold';
}

.w-nest.w-tab-global-header-screen.wrapper>.content.display>.text-wrapper>p {
    text-align: justify;
    font-size: 1.6vw;
    margin-block: 0.5vw;
    line-height: 150%;
    width: 69%;
}
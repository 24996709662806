.w-pc-contact-detail-screen{
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    color: #2c2c34;
}
.w-pc-contact-detail-screen.wrapper{
    position: relative;
}

.w-pc-contact-detail-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-contact-detail-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-contact-detail-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-contact-detail-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-contact-detail-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-contact-detail-screen.wrapper>.content.display{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-inline: 3vw;
    padding-block: 5vw;
}

.w-pc-contact-detail-screen.wrapper>.content.display>.section{
    box-sizing: border-box;
    margin-bottom: 2.5vw;
}

.w-pc-contact-detail-screen.wrapper>.content.display>.section>h2{
    font-size: 3vw;
    margin-bottom: 1vw;
}
.w-pc-contact-detail-screen.wrapper>.content.display>.section>p{
    font-size: 1.5vw;
}

.w-pc-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-top: 1.4vw;
}
.w-pc-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>.frame{
    width: 3vw;
    height:3vw;
    margin-right: 0.5vw;
    overflow: hidden;
}
.w-pc-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>.frame>img{
    height: 100%;
}
.w-pc-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>p{
    font-size: 1.5vw;
}


/* mobile */
.w-mob-contact-detail-screen {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    color: #2c2c34;
}

.w-mob-contact-detail-screen.wrapper {
    position: relative;
}

.w-mob-contact-detail-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-contact-detail-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-mob-contact-detail-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-mob-contact-detail-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-contact-detail-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-contact-detail-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-inline: 3vw;
    padding-block: 10vw;
}

.w-mob-contact-detail-screen.wrapper>.content.display>.section {
    box-sizing: border-box;
    margin-bottom: 10vw;
    /* border: 1px solid red; */
}

.w-mob-contact-detail-screen.wrapper>.content.display>.section>h2 {
    font-size: 9vw;
    margin-bottom: 1vw;
}

.w-mob-contact-detail-screen.wrapper>.content.display>.section>p {
    font-size: 5vw;
}

.w-mob-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-top: 1.4vw;
}

.w-mob-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>.frame {
    width: 5vw;
    height: 5vw;
    margin-right: 3vw;
    overflow: hidden;
}

.w-mob-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>.frame>img {
    height: 100%;
}

.w-mob-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>p {
    font-size: 5vw;
}


/* tab device */
.w-tab-contact-detail-screen {
    height: 35vh;
    width: 100%;
    box-sizing: border-box;
    color: #2c2c34;
}

.w-tab-contact-detail-screen.wrapper {
    position: relative;
}

.w-tab-contact-detail-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-tab-contact-detail-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-tab-contact-detail-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-tab-contact-detail-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-tab-contact-detail-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-tab-contact-detail-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-inline: 3vw;
    padding-block: 5vw;
}

.w-tab-contact-detail-screen.wrapper>.content.display>.section {
    box-sizing: border-box;
    margin-bottom: 2.5vw;
}

.w-tab-contact-detail-screen.wrapper>.content.display>.section>h2 {
    font-size: 3vw;
    margin-bottom: 1vw;
}

.w-tab-contact-detail-screen.wrapper>.content.display>.section>p {
    font-size: 1.5vw;
}

.w-tab-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-top: 1.4vw;
}

.w-tab-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>.frame {
    width: 3vw;
    height: 3vw;
    margin-right: 0.5vw;
    overflow: hidden;
}

.w-tab-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>.frame>img {
    height: 100%;
}

.w-tab-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>p {
    font-size: 1.5vw;
}


/* nest device */
.w-nest.w-tab-contact-detail-screen {
    height: 70vh;
    width: 100%;
    box-sizing: border-box;
    color: #2c2c34;
}

.w-nest.w-tab-contact-detail-screen.wrapper {
    position: relative;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-inline: 3vw;
    padding-block: 5vw;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.content.display>.section {
    box-sizing: border-box;
    margin-bottom: 2.5vw;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.content.display>.section>h2 {
    font-size: 3vw;
    margin-bottom: 1vw;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.content.display>.section>p {
    font-size: 1.5vw;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-top: 1.4vw;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>.frame {
    width: 3vw;
    height: 3vw;
    margin-right: 0.5vw;
    overflow: hidden;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>.frame>img {
    height: 100%;
}

.w-nest.w-tab-contact-detail-screen.wrapper>.content.display>.section>.object-wrapper>p {
    font-size: 1.5vw;
}
.w-pc-global-card-collage{
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}
.w-pc-global-card-collage.wrapper{
    position: relative;
}

.w-pc-global-card-collage.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-global-card-collage.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-global-card-collage.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-global-card-collage.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-global-card-collage.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-global-card-collage.wrapper>.content.display{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 3vw;
}

.w-pc-global-card-collage.wrapper>.content.display>.wrapper.collage{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 30vh;
    /* overflow-y: auto; */
    box-sizing: border-box;
    width: 85%;
}
.w-pc-global-card-collage.wrapper>.content.display>.wrapper.collage>.item{
    box-sizing: border-box;
    padding: 1vw;
    /* border: 1px solid red; */
}
.w-pc-global-card-collage.wrapper>.content.display>.wrapper.collage>.item>.card {
    box-sizing: border-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.189);
    border-radius: 15px;
    height: 100%;
    padding: 3px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #32323e;
}



/* mobile */
.w-mob-global-card-collage {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.w-mob-global-card-collage.wrapper {
    position: relative;
}

.w-mob-global-card-collage.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-global-card-collage.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-mob-global-card-collage.wrapper>.background>.frame>img {
    width: 100%;
}

.w-mob-global-card-collage.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-global-card-collage.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-global-card-collage.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 3vw;
    height: 100%;
    /* background-color: yellow; */
}

.w-mob-global-card-collage.wrapper>.content.display>.wrapper.collage {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 10vh;
    /* overflow-y: auto; */
    box-sizing: border-box;
    width: 85%;
    height: 90%;
    /* border: 1px solid red; */
}

.w-mob-global-card-collage.wrapper>.content.display>.wrapper.collage>.item {
    box-sizing: border-box;
    padding: 1vw;
    /* border: 1px solid red; */
}

.w-mob-global-card-collage.wrapper>.content.display>.wrapper.collage>.item>.card {
    box-sizing: border-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.189);
    border-radius: 15px;
    height: 100%;
    padding: 3px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #32323e;
}

.w-mob-global-card-collage.wrapper>.content.display>.wrapper.collage>.item>.card>
h2{
    font-size: 5vw;;
}
.w-mob-global-card-collage.wrapper>.content.display>.wrapper.collage>.item>.card>p {
    font-size: 3vw;
    ;
}


/* tab device */
.w-tab-global-card-collage {
    height: 60vh;
    width: 100%;
    box-sizing: border-box;
}

.w-tab-global-card-collage.wrapper {
    position: relative;
}

.w-tab-global-card-collage.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-tab-global-card-collage.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-tab-global-card-collage.wrapper>.background>.frame>img {
    width: 100%;
}

.w-tab-global-card-collage.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-tab-global-card-collage.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-tab-global-card-collage.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 3vw;
}

.w-tab-global-card-collage.wrapper>.content.display>.wrapper.collage {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 15vh;
    /* overflow-y: auto; */
    box-sizing: border-box;
    width: 85%;
}

.w-tab-global-card-collage.wrapper>.content.display>.wrapper.collage>.item {
    box-sizing: border-box;
    padding: 1vw;
    /* border: 1px solid red; */
}

.w-tab-global-card-collage.wrapper>.content.display>.wrapper.collage>.item>.card{
    box-sizing: border-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.189);
    border-radius: 15px;
    height: 100%;
    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #32323e;
}
.w-tab-global-card-collage.wrapper>.content.display>.wrapper.collage>.item>.card>h2{
    font-size: 1.5vh;
    text-align: center;
}
.w-tab-global-card-collage.wrapper>.content.display>.wrapper.collage>.item>.card>p{
    font-size: 1.1vh;
    text-align: center;
}
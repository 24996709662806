.w-pc-floating-icon {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 99999;
}

.w-pc-floating-icon>img {
    height: 50px;
    margin-inline: 5px;
    cursor: pointer;
    transition: 0.3s ease;
}

.w-pc-floating-icon>img:hover {
    transform: scale(1.08);
}

.w-tab-floating-icon {
    position: fixed;
    right: 30px;
    bottom: 30px;
}

.w-tab-floating-icon>img {
    height: 50px;
    margin-inline: 5px;
    cursor: pointer;
}

.w-tab-floating-icon>img:hover {
    filter: sepia(70%)
}

.w-mob-floating-icon {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 99999;
}

.w-mob-floating-icon>img {
    height: 36px;
    margin-inline: 7px;
    cursor: pointer;
}

.w-mob-floating-icon>img:hover {
    filter: sepia(70%)
}
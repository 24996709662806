.w-pc-app-footer{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    width: 100%;
    height: 31vh;
    background-color: #2c2c34;
    color: #fff;
}
.w-pc-app-footer>.section.anchors{
    width: 60%;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.w-pc-app-footer>.section.anchors>.anchor-group{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-inline: 3vw;
    height: 70%;
    /* border: 1px solid red; */
    box-sizing: border-box;
    width: 25%
}

.w-pc-app-footer>.section.anchors>.anchor-group>h2{
    margin-bottom: 0.6vw;
    font-size: 1.5vw;
}
.w-pc-app-footer>.section.anchors>.anchor-group>p {
    margin-bottom: 0.3vw;
    font-size: 0.9vw;
    cursor: pointer;
}
.w-pc-app-footer>.section.anchors>.anchor-group>p:hover{
    opacity: 88%;
}
.w-pc-app-footer>.section.anchors>.anchor-group>p:active {
    opacity: 95%;
}

.w-pc-app-footer>.section.logo {
    width: 40%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.w-pc-app-footer>.section.logo>.frame{
    height: 20vh;
    width: 80%;
    /* background-color: red; */

    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}

.w-pc-app-footer>.section.logo>.frame>img{
    height: 80%;
    filter: invert(90%);
}


.w-pc-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons {
    display: flex;
    flex-direction: row !important;
    align-items: start !important;
    justify-content: center !important;
    /* background-color: yellow; */
    box-sizing: border-box !important;
    width: 50% !important;
}

.w-pc-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame {
    height: 5.5vh;
    width: 5.5vh;
    overflow: hidden;
    /* background-color: red; */
    margin-inline: 1.5vw;
    transition: 0.3s ease-out;
}

.w-pc-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame>img {
    width: 100%;
    filter: invert(90%);
    cursor: pointer;
}

.w-pc-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame:hover {

    transform: scale(1.1);
}







/* mobile */
.w-mob-app-footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #2c2c34;
    color: #fff;
}
.w-mob-app-footer>.section{
    box-sizing: border-box;
    /* border: 1px solid red; */
}

.w-mob-app-footer>.section.anchors {
    width: 100%;
    height: 60%;
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.w-mob-app-footer>.section.anchors>.anchor-group {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-inline: 3vw;
    height: 38%;
    width: 50%;
    /* border: 1px solid red; */
    margin-bottom: 0vh;
}

.w-mob-app-footer>.section.anchors>.anchor-group>h2 {
    margin-bottom: 1vw;
    font-size: 3vh
}

.w-mob-app-footer>.section.anchors>.anchor-group>p {
    margin-bottom: 1vw;
    font-size: 1.8vh;
}

.w-mob-app-footer>.section.logo {
    width: 100%;
    height: 25%;
    /* background-color: rgb(46, 15, 112); */
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.w-mob-app-footer>.section.logo>.frame {
    height: 20vh;
    width: 80%;
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-app-footer>.section.logo>.frame>img {
    height: 70%;
    filter: invert(90%);
}

.w-mob-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons{
    display: flex;
    flex-direction: row !important;
    align-items: start !important;
    justify-content: center!important;
    /* background-color: yellow; */
    box-sizing: border-box !important;
    width: 70% !important;
}
.w-mob-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame{
    height: 5.5vh;
    width: 5.5vh;
    overflow: hidden;
    /* background-color: red; */
    margin-inline: 3vw;
    transition: 0.3s ease-out;
}
.w-mob-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame>img {
    width: 100%;
    filter: invert(90%);
    cursor: pointer;
    transition: 0.3s ease-out;
}
.w-mob-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame:hover {
    transform: scale(1.1);
}


/* tab device */
.w-tab-app-footer {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    width: 100%;
    height: 20vh;
    background-color: #2c2c34;
    color: #fff;
}

.w-tab-app-footer>.section.anchors {
    width: 70%;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.w-tab-app-footer>.section.anchors>.anchor-group {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-inline: 3vw;
    height: 70%;
    /* border: 1px solid red; */
    box-sizing: border-box;
    width: 30%
}

.w-tab-app-footer>.section.anchors>.anchor-group>h2 {
    margin-bottom: 0.6vh;
    font-size: 1.5vh;
}

.w-tab-app-footer>.section.anchors>.anchor-group>p {
    margin-bottom: 0.3vw;
    font-size: 0.9vh;
    cursor: pointer;
}

.w-tab-app-footer>.section.anchors>.anchor-group>p:hover {
    opacity: 88%;
}

.w-tab-app-footer>.section.anchors>.anchor-group>p:active {
    opacity: 95%;
}

.w-tab-app-footer>.section.logo {
    width: 30%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.w-tab-app-footer>.section.logo>.frame {
    height: 20vh;
    width: 80%;
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-app-footer>.section.logo>.frame>img {
    height: 30%;
    filter: invert(90%);
}


.w-tab-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons {
    display: flex;
    flex-direction: row !important;
    align-items: start !important;
    justify-content: center !important;
    /* background-color: yellow; */
    box-sizing: border-box !important;
    width: 50% !important;
}

.w-tab-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame {
    height: 3vh;
    width: 3vh;
    overflow: hidden;
    /* background-color: red; */
    margin-inline: 1.5vw;
    transition: 0.3s ease-out;
}

.w-tab-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame>img {
    width: 100%;
    filter: invert(90%);
    cursor: pointer;
}

.w-tab-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame:hover {

    transform: scale(1.1);
}


/* nest device */
.w-nest.w-tab-app-footer {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    width: 100%;
    height: 30vh;
    background-color: #2c2c34;
    color: #fff;
}

.w-nest.w-tab-app-footer>.section.anchors {
    width: 70%;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.w-nest.w-tab-app-footer>.section.anchors>.anchor-group {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-inline: 3vw;
    height: 70%;
    /* border: 1px solid red; */
    box-sizing: border-box;
    width: 30%
}

.w-nest.w-tab-app-footer>.section.anchors>.anchor-group>h2 {
    margin-bottom: 1.6vh;
    font-size: 2.5vh;
}

.w-nest.w-tab-app-footer>.section.anchors>.anchor-group>p {
    margin-bottom: 0.8vw;
    font-size: 1.5vh;
    cursor: pointer;
}

.w-nest.w-tab-app-footer>.section.anchors>.anchor-group>p:hover {
    opacity: 88%;
}

.w-nest.w-tab-app-footer>.section.anchors>.anchor-group>p:active {
    opacity: 95%;
}

.w-nest.w-tab-app-footer>.section.logo {
    width: 30%;
    height: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.w-nest.w-tab-app-footer>.section.logo>.frame {
    height: 30vh;
    width: 80%;
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-nest.w-tab-app-footer>.section.logo>.frame>img {
    height: 35%;
    filter: invert(90%);
}


.w-nest.w-tab-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons {
    display: flex;
    flex-direction: row !important;
    align-items: start !important;
    justify-content: center !important;
    /* background-color: yellow; */
    box-sizing: border-box !important;
    width: 50% !important;
}

.w-nest.w-tab-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame {
    height: 4.5vh;
    width: 4.5vh;
    overflow: hidden;
    /* background-color: red; */
    margin-inline: 1.5vw;
    transition: 0.3s ease-out;
}

.w-nest.w-tab-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame>img {
    width: 100%;
    filter: invert(90%);
    cursor: pointer;
}

.w-nest.w-tab-app-footer>.section.anchors>.anchor-group.footer-anchor-group-icons>.footer-anchor-frame:hover {

    transform: scale(1.1);
}
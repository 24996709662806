.w-pc-home-values-screen{
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
  
}
.w-pc-home-values-screen.wrapper{
    position: relative;
}

.w-pc-home-values-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-home-values-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-home-values-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-home-values-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-home-values-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    overflow: hidden;
}

.w-pc-home-values-screen.wrapper>.content.display{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2a4261;
    padding-inline: 3vw;
}
.w-pc-home-values-screen.wrapper>.content.display>.wrapper.grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 55vh;
    box-sizing: border-box;
    padding-inline: 5vw;
    width: 100%;
}
.w-pc-home-values-screen.wrapper>.content.display>.wrapper.grid>.item{
    box-sizing: border-box;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.w-pc-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80%;
    box-sizing: border-box;
}

.w-pc-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.w-pc-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.frame{
    box-sizing: border-box;
    width: 100%;
    height: 40%;
    /* background-color: yellow; */
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.w-pc-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.frame>img{
    height: 90%;
    filter: invert(96%);
}
.w-pc-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper{
    box-sizing: border-box;
    width: 100%;
    height: 60%;
    /* background-color: green; */
    padding-block: 1.5vw;
}

.w-pc-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper>h2{
    box-sizing: border-box;
    color: #fff;
}
.w-pc-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper>p {
    box-sizing: border-box;
    color: #fff;
    text-align: justify;
    margin-block: 0.5vw;;
}



/* mobile */

.w-mob-home-values-screen {
    height: 190vh;
    width: 100%;
    box-sizing: border-box;
}

.w-mob-home-values-screen.wrapper {
    position: relative;
}

.w-mob-home-values-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-home-values-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-mob-home-values-screen.wrapper>.background>.frame>img {
    height: 100%;
}

.w-mob-home-values-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-home-values-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    overflow: hidden;
}

.w-mob-home-values-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2a4261;
    padding-inline: 2vw;
}

.w-mob-home-values-screen.wrapper>.content.display>.wrapper.grid {
    display: grid;
    grid-template-columns: 1fr;
    /* 55 x 3 */
    grid-auto-rows: 55vh;
    row-gap: 2vh;
    box-sizing: border-box;
    padding-inline: 3vw;
    width: 100%;
    /* border: 1px dashed yellow; */
}

.w-mob-home-values-screen.wrapper>.content.display>.wrapper.grid>.item {
    box-sizing: border-box;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  
    /* border: 1px dashed red; */
}

.w-mob-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
    box-sizing: border-box;
    /* border: 1px dashed yellow; */
    
}

.w-mob-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.w-mob-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.frame {
    box-sizing: border-box;
    width: 100%;
    height: 35%;
    /* background-color: yellow; */
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.frame>img {
    height: 87%;
    filter: invert(96%);
}

.w-mob-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 65%;
    padding-block: 3vh;
    /* background-color: green; */
}

.w-mob-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper>h2 {
    box-sizing: border-box;
    color: #fff;
}

.w-mob-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper>p {
    box-sizing: border-box;
    color: #fff;
    text-align: justify;
    margin-block: 0.5vw;
    font-size: 4vw;
}


/* tab device*/
.w-tab-home-values-screen {
    height: 50vh;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid red; */

}

.w-tab-home-values-screen.wrapper {
    position: relative;
    /* border: 1px solid green; */
    box-sizing: border-box;
}

.w-tab-home-values-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
    background-color: #2a4261;
}

.w-tab-home-values-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #2a4261;
}


.w-tab-home-values-screen.wrapper>.background>.frame>img {
    width: 100%;
    background-color: #2a4261;
}

.w-tab-home-values-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    background-color: #2a4261;
}

.w-tab-home-values-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    overflow: hidden;
    /* border: 1px solid yellow; */
    background-color: #2a4261;
}

.w-tab-home-values-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2a4261;
    padding-inline: 3vw;
   
}

.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 43vh;
    box-sizing: border-box;
    padding-inline: 0vw;
    width: 100%;
    /* height: 40vh; */
    /* background-color: red; */
    position: relative;
   
}

.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item {
    box-sizing: border-box;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 55vh; */
    /* background-color: aquamarine; */
    height: 100%;
}

.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 85%;
    box-sizing: border-box;
}

.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /* border: 1px dashed white; */
    /* background-color: white;; */
}

.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.frame {
    box-sizing: border-box;
    width: 100%;
    height: 40%;
    /* background-color: yellow; */
    /* border: 1px dashed yellow; */
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.frame>img {
    height: 40%;
    filter: invert(96%);
}

.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 60%;
    /* background-color: green; */
    /* padding-block: 1.5vw; */
    /* border: 1px dashed yellow; */
}

.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper>h2 {
    box-sizing: border-box;
    color: #fff;
    font-size: 2.7vw;
}

.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper>p {
    box-sizing: border-box;
    color: #fff;
    text-align: justify;
    font-size: 1.7vw;
    margin-top: 1vw;
}


/* nest */
/* tab device*/
.w-nest.w-tab-home-values-screen {
    height: 110vh;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid red; */

}

.w-nest.w-tab-home-values-screen.wrapper {
    position: relative;
    /* border: 1px solid green; */
    box-sizing: border-box;
}

.w-nest.w-tab-home-values-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
    background-color: #2a4261;
}

.w-nest.w-tab-home-values-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #2a4261;
}


.w-nest.w-tab-home-values-screen.wrapper>.background>.frame>img {
    width: 100%;
    background-color: #2a4261;
}

.w-nest.w-tab-home-values-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    background-color: #2a4261;
}

.w-nest.w-tab-home-values-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    overflow: hidden;
    /* border: 1px solid yellow; */
    background-color: #2a4261;
}

.w-nest.w-tab-home-values-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2a4261;
    padding-inline: 3vw;

}

.w-nest.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 100vh;
    box-sizing: border-box;
    
    width: 100%;
    /* height: 40vh; */
    /* background-color: red; */
    position: relative;

}

.w-nest.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item {
    box-sizing: border-box;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 55vh; */
    /* background-color: aquamarine; */
    height: 100%;
}

.w-nest.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 85%;
    box-sizing: border-box;
}

.w-nest.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /* border: 1px dashed white; */
    /* background-color: white;; */
}

.w-nest.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.frame {
    box-sizing: border-box;
    width: 100%;
    height: 40%;
    /* background-color: yellow; */
    /* border: 1px dashed yellow; */
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-nest.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.frame>img {
    height: 40%;
    filter: invert(96%);
}

.w-nest.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 60%;
    /* background-color: green; */
    /* padding-block: 1.5vw; */
    /* border: 1px dashed yellow; */
}

.w-nest.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper>h2 {
    box-sizing: border-box;
    color: #fff;
    font-size: 2.7vw;
}

.w-nest.w-tab-home-values-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.section.text-wrapper>p {
    box-sizing: border-box;
    color: #fff;
    text-align: justify;
    font-size: 1.7vw;
    margin-top: 1vw;
}
.w-pc-global-conveyor-screen{
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}
.w-pc-global-conveyor-screen.wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: yellow; */
    /* background-color: #2a4261; */
    background-color: gray;
    height: 100vh;
}

.w-pc-global-conveyor-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-global-conveyor-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-global-conveyor-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-global-conveyor-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-global-conveyor-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;

}

.w-pc-global-conveyor-screen.wrapper>.content.display{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 3vw;
    box-sizing: border-box;
    background-color: #2a4261;
    /* background-color: black; */
    /* overflow: hidden; */
    /* overflow-x: scroll; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}
/* .scroll-wrapper{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
} */
/* .scroll-wrapper.display{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-inline:1vw;
    box-sizing: border-box;
} */
.scroll-wrapper.frame.left{
    width: 3vw;
    height: 3vw;
    overflow: hidden;
    opacity: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 1%; 
    cursor: pointer;
}
.scroll-wrapper.frame>img{
    height: 5vh;
    filter: invert(90%);
}
.scroll-wrapper.frame>img:hover{
    transform: scale(0.9);
}
.scroll-wrapper.frame.right{
    transform: rotate(180deg);
    width: 3vw;
        height: 3vw;
        overflow: hidden;
        opacity: 50%;
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 1%;
        cursor: pointer;
}

.w-pc-global-conveyor-screen.wrapper>.content.display>.text-wrapper>h2{
    color: #fff;
    font-size: 3.8vw;
    margin-block: 3vw;
}

.w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid{
    display: flex;
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}

.w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item{
    min-width: 40vw;
    margin-right: 20px;
    height: 35vw;
    width: 300px;
    border-radius: 8px;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card{
    height: 90%;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 5%;
    /* background-color:#f0f0f0; */
}

.w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card 
>.frame{
    height: 70%;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s ease;
}
.w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame>img{
    width: 100%;
    border-radius: 20px;
    transition: 0.3s ease;
    cursor: pointer;
}
/* .w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame>img:hover{
    transform: scale(1.1);
    transition: 0.3s ease;
} */

.w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>
.text-wrapper{
    height: 30%;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
}

.w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper{
    padding-block: 1vw;
}

.w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>
h2{
    color: #fff;
    font-size: 2.8vw;
    cursor: pointer;
    transition: 0.3s ease;
}
.w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>h2:hover{
    transition: 0.3s ease;
    color: #cecbcb;
}
.w-pc-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>
p{
    color: #fff;
    font-size: 1.2vw;
    text-align: justify;
    width: 90%;
}


/* mobile */
.w-mob-global-conveyor-screen {
    height: 180vh;
    width: 100%;
    box-sizing: border-box;
    background-color: black;
}

.w-mob-global-conveyor-screen.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: yellow; */
    background-color: #2a4261;
    height: 180vh;
}

.w-mob-global-conveyor-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-global-conveyor-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-mob-global-conveyor-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-mob-global-conveyor-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-global-conveyor-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-global-conveyor-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 3vw;
    box-sizing: border-box;
    background-color: #2a4261;
    /* background-color: black; */
    /* overflow: hidden; */
    /* overflow-x: scroll; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.w-mob-global-conveyor-screen.wrapper>.content.display>.text-wrapper>h2 {
    color: #fff;
    font-size: 9vw;
    margin-block: 3vw;
    padding-inline: 8vw;
}

.w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid {
    display: flex;
    width: 100%;
    height: 100%;
    /* overflow-x: auto;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /* border: 1px dashed yellow; */

}


.w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item {
    /* min-width: 500px;
    height: 30vw;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    scroll-snap-align: start; */
    min-width: 40vw;
    margin-right: 20px;
    height: 42vh;
    width: 300px;
    /* background-color: #f0f0f0; */
    border-radius: 8px;
    scroll-snap-align: start;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card {
    height: 90%;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 5%;
    /* background-color:#f0f0f0; */
    /* border: 1px dashed yellow; */
}

.w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame {
    height: 70%;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s ease;
}

.w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame>img {
    width: 100%;
    border-radius: 20px;
    transition: 0.3s ease;
}

/* .w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame>img:hover{
    transform: scale(1.1);
    transition: 0.3s ease;
} */

.w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper {
    height: 30%;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
}

.w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper {
    padding-block: 1vw;
}

.w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>h2 {
    color: #fff;
    font-size: 6vw;
    cursor: pointer;
    transition: 0.3s ease;
}

.w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>h2:hover {
    transition: 0.3s ease;
    color: #cecbcb;
    
}

.w-mob-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>p {
    color: #fff;
    font-size: 3vw;
    text-align: justify;
    width: 100%;
}



/* tab device */
.w-tab-global-conveyor-screen {
    /* height: 50vh; */
    width: 100%;
    box-sizing: border-box;
   
}

/* tab-content-display-parent-height*/

.w-tab-global-conveyor-screen.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: yellow; */
    /* background-color: #2a4261; */
    /* background-color: gray; */
    height: 50vh;
    padding-block: 2.5vh;
    background-color: #2a4261;
     /* background-color: yellow; */
     /* background-color: black; */
}

.w-tab-global-conveyor-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-tab-global-conveyor-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-tab-global-conveyor-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-tab-global-conveyor-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-tab-global-conveyor-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;

}

.w-tab-global-conveyor-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 3vw;
    box-sizing: border-box;
    /* background-color: #2a4261; */
    /* background-color: yellow; */
    /* background-color: black; */
    /* overflow: hidden; */
    /* overflow-x: scroll; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

/* .scroll-wrapper{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
} */
/* .scroll-wrapper.display{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-inline:1vw;
    box-sizing: border-box;
} */
.scroll-wrapper.frame.left {
    width: 3vw;
    height: 3vw;
    overflow: hidden;
    opacity: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 1%;
    cursor: pointer;
}

.scroll-wrapper.frame>img {
    height: 5vh;
    filter: invert(90%);
}

.scroll-wrapper.frame>img:hover {
    transform: scale(0.9);
}

.scroll-wrapper.frame.right {
    transform: rotate(180deg);
    width: 3vw;
    height: 3vw;
    overflow: hidden;
    opacity: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 1%;
    cursor: pointer;
}

/* tab-content-display-stack-row-1 */
.w-tab-global-conveyor-screen.wrapper>.content.display>.text-wrapper{
    /* background-color: aquamarine; */
    height: 10vh;
}

.w-tab-global-conveyor-screen.wrapper>.content.display>.text-wrapper>h2 {
    color: #fff;
    font-size: 3.8vh;
    margin-block: 3vw;
}

/* tab-content-display-stack-row-2 */
.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid {
    display: flex;
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    /* background-color: green; */
}

.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item{
    min-width: 30vh;
    margin-right: 20px;
    /* height: 35vw; */
    /* height: 40vh; */
    border-radius: 8px;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    /* background-color: red; */
}

/* tab-card-stack-parent */
.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card {
    /* height: 100%; */
    height: 40vh;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 5%;
    /* background-color:#f0f0f0; */
}

/* tab-card-stack-row-1 */
.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame {
    height: 50%;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
    background-color: red;
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s ease;
}

.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame>img {
    width: 100%;
    border-radius: 20px;
    transition: 0.3s ease;
    cursor: pointer;
}

/* .w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame>img:hover{
    transform: scale(1.1);
    transition: 0.3s ease;
} */

/* tab-card-stack-row-2 */
.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper {
    height: 50%;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
    /* background-color: yellow; */
}

.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper {
    padding-block: 1vw;
}

.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>h2 {
    color: #fff;
    font-size: 2.8vw;
    cursor: pointer;
    transition: 0.3s ease;
}

.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>h2:hover {
    transition: 0.3s ease;
    color: #cecbcb;
}

.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>p {
    color: #fff;
    font-size: 1.6vw;
    text-align: justify;
    width: 90%;
}



/* nest device */
/* tab device */
.w-nest.w-tab-global-conveyor-screen {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    /* background-color: black; */

}

/* tab-content-display-parent-height*/

.w-nest.w-tab-global-conveyor-screen.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: yellow; */
    /* background-color: #2a4261; */
    /* background-color: gray; */
    height: 100vh;
    padding-block: 2.5vh;
    background-color: #2a4261;
    /* background-color: yellow; */
    /* background-color: black; */
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;

}

.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-left: 3vw;
    box-sizing: border-box;
    /* background-color: #2a4261; */
    /* background-color: yellow; */
    /* background-color: black; */
    /* overflow: hidden; */
    /* overflow-x: scroll; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

/* .scroll-wrapper{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
} */
/* .scroll-wrapper.display{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-inline:1vw;
    box-sizing: border-box;
} */
.scroll-wrapper.frame.left {
    width: 3vw;
    height: 3vw;
    overflow: hidden;
    opacity: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 1%;
    cursor: pointer;
}

.scroll-wrapper.frame>img {
    height: 5vh;
    filter: invert(90%);
}

.scroll-wrapper.frame>img:hover {
    transform: scale(0.9);
}

.scroll-wrapper.frame.right {
    transform: rotate(180deg);
    width: 3vw;
    height: 3vw;
    overflow: hidden;
    opacity: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 1%;
    cursor: pointer;
}

/* tab-content-display-stack-row-1 */
.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.text-wrapper{
    /* background-color: aquamarine; */
    /* height: 10vh; */
    margin-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.text-wrapper>h2{
    color: #fff;
    font-size: 6vh;
}

/* tab-content-display-stack-row-2 */
.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid {
    display: flex;
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    /* background-color: green; */
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item {
    min-width: 33vw;
    margin-right: 20px;
    /* height: 35vw; */
    /* height: 40vh; */
    border-radius: 8px;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    /* background-color: red; */
}

/* tab-card-stack-parent */
.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card {
    /* height: 100%; */
    height: 50vh;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 5%;
    /* background-color:#f0f0f0; */
}

/* tab-card-stack-row-1 */
.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame{
    height: 60%;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid yellow; */
    background-color: red;
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s ease;
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame>img{
    width: 100%;
    border-radius: 20px;
    transition: 0.3s ease;
    cursor: pointer;
}

/* .w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.frame>img:hover{
    transform: scale(1.1);
    transition: 0.3s ease;
} */

/* tab-card-stack-row-2 */
.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper{
    height: 40%;
    width: 100%;
    box-sizing: border-box;
    /* border: 1px solid black; */
    /* background-color: yellow; */
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper {
    padding-block: 1vw;
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>h2 {
    color: #fff;
    font-size: 2.8vh;
    cursor: pointer;
    transition: 0.3s ease;
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>h2:hover {
    transition: 0.3s ease;
    color: #cecbcb;
}

.w-nest.w-tab-global-conveyor-screen.wrapper>.content.display>.wrapper.grid>.item>.card>.text-wrapper>p {
    color: #fff;
    font-size: 1.3vw;
    text-align: justify;
    width: 90%;
}
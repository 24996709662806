.w-pc-service-submenu-screen{
    height: 110vh;
    width: 100%;
    box-sizing: border-box;
    color: #2c2c34;
}
.w-pc-service-submenu-screen.wrapper{
    position: relative;
}

.w-pc-service-submenu-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-service-submenu-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-service-submenu-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-service-submenu-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-service-submenu-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-service-submenu-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-inline: 3vw;
}
.w-pc-service-submenu-screen.wrapper>.content.display>.menubar{
    height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    /* background-color: yellow; */
    box-sizing: border-box;
}
.w-pc-service-submenu-screen.wrapper>.content.display>.menubar>.bar{
    margin-right: 2vw;
}
.w-pc-service-submenu-screen.wrapper>.content.display>.menubar>.bar>p{
    font-size: 1vw;
    cursor: pointer;
    transition: 0.3s;
    color: #32323e;
}
.w-pc-service-submenu-screen.wrapper>.content.display>.menubar>.bar>p:hover{
    font-size: 1vw;
    cursor: pointer;
    transition: 0.3s;
    color: #777790;
}
.w-pc-service-submenu-screen.wrapper>.content.display>.global-submenu-details{
    /* border: 1px solid red; */
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
}


/* mobile */
.w-mob-service-submenu-screen {
    height: 125vh;
    width: 100%;
    box-sizing: border-box;
    color: #2c2c34;
}

.w-mob-service-submenu-screen.wrapper {
    position: relative;
}

.w-mob-service-submenu-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-service-submenu-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-mob-service-submenu-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-mob-service-submenu-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-service-submenu-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-service-submenu-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-inline: 3vw;
}

.w-mob-service-submenu-screen.wrapper>.content.display>.menubar {
    height: 25vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    /* background-color: yellow; */
    box-sizing: border-box;
    padding-inline: 5vw;
}

.w-mob-service-submenu-screen.wrapper>.content.display>.menubar>.bar {
    margin-block: 2vw;
}

.w-mob-service-submenu-screen.wrapper>.content.display>.menubar>.bar>p {
    font-size: 1.6vh;
    cursor: pointer;
    transition: 0.3s;
    color: #32323e;
    cursor: pointer;
}

.w-mob-service-submenu-screen.wrapper>.content.display>.menubar>.bar>p:hover {
    font-size: 1.6vh;
    cursor: pointer;
    transition: 0.3s;
    color: #777790;
}

.w-mob-service-submenu-screen.wrapper>.content.display>.global-submenu-details {
    /* border: 1px solid red; */
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
}


/* tab device */
.w-tab-service-submenu-screen {
    height: 58vh;
    width: 100%;
    box-sizing: border-box;
    color: #2c2c34;
}

.w-tab-service-submenu-screen.wrapper {
    position: relative;
}

.w-tab-service-submenu-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-tab-service-submenu-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-tab-service-submenu-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-tab-service-submenu-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-tab-service-submenu-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-tab-service-submenu-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-inline: 3vw;
}

.w-tab-service-submenu-screen.wrapper>.content.display>.menubar {
    height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    /* background-color: yellow; */
    box-sizing: border-box;
}

.w-tab-service-submenu-screen.wrapper>.content.display>.menubar>.bar {
    margin-right: 2vw;
}

.w-tab-service-submenu-screen.wrapper>.content.display>.menubar>.bar>p {
    font-size: 1vw;
    cursor: pointer;
    transition: 0.3s;
    color: #32323e;
}

.w-tab-service-submenu-screen.wrapper>.content.display>.menubar>.bar>p:hover {
    font-size: 1vw;
    cursor: pointer;
    transition: 0.3s;
    color: #777790;
}

.w-tab-service-submenu-screen.wrapper>.content.display>.global-submenu-details {
    /* border: 1px solid red; */
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
}


/* nest device */
.w-nest.w-tab-service-submenu-screen {
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    color: #2c2c34;
}

.w-nest.w-tab-service-submenu-screen.wrapper {
    position: relative;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.background>.frame>img {
    width: 100%;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: cadetblue; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-inline: 3vw;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.content.display>.menubar {
    height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    /* background-color: yellow; */
    box-sizing: border-box;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.content.display>.menubar>.bar {
    margin-right: 2vw;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.content.display>.menubar>.bar>p {
    font-size: 1vw;
    cursor: pointer;
    transition: 0.3s;
    color: #32323e;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.content.display>.menubar>.bar>p:hover {
    font-size: 1vw;
    cursor: pointer;
    transition: 0.3s;
    color: #777790;
}

.w-nest.w-tab-service-submenu-screen.wrapper>.content.display>.global-submenu-details {
    /* border: 1px solid red; */
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
}
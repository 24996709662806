.w-pc-global-submenu-detail{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}
.w-pc-global-submenu-detail.wrapper{
    position: relative;
}

.w-pc-global-submenu-detail.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-pc-global-submenu-detail.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-pc-global-submenu-detail.wrapper>.background>.frame>img {
    width: 100%;
}

.w-pc-global-submenu-detail.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-pc-global-submenu-detail.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    color: #2c2c34;
}

.w-pc-global-submenu-detail.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.w-pc-global-submenu-detail.wrapper>.content.display>.section.header{
    height: 50%;
    width: 100%;
    /* background-color: yellow; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.w-pc-global-submenu-detail.wrapper>.content.display>.section.header>.sub{
    width: 50%;
    box-sizing: border-box;
    /* border: 1px solid red; */
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.w-pc-global-submenu-detail.wrapper>.content.display>.section.header>.sub.text-wrapper>h2{
    font-size: 3.8vw;

}
.w-pc-global-submenu-detail.wrapper>.content.display>.section.header>.sub.text-wrapper>p {
    font-size: 1.5vw;
    width: 90%;
}
.w-pc-global-submenu-detail.wrapper>.content.display>.section.header>.sub.frame{
    /* background-color: red; */
    overflow: hidden;
    border-radius: 20px;
    width: 44%;
}
.w-pc-global-submenu-detail.wrapper>.content.display>.section.header>.sub.frame>img{
    width: 100%;
}



.w-pc-global-submenu-detail.wrapper>.content.display>.section.cardholder{
    height: 50%;
    width: 100%;
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
}

.w-pc-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card{
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.297);
    border-radius: 20px;
    margin-right: 2vw;
    height: 90%;
    width: 23%;
    /* background-color: red; */
    padding-inline: 1vw;
    padding-block: 1vw;
}

.w-pc-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub{
    width: 100%;
}

.w-pc-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.frame{
    /* background-color: red; */
    height: 40%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    overflow: hidden;
}
.w-pc-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.frame>img{
    height: 80%;
}

.w-pc-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.text-wrapper{
    height: 60%;
    padding-inline: 1vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-top: 1.5vw;
}

.w-pc-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.text-wrapper>h2{
    font-size: 1.7vw;
    margin-bottom: 0.3vw;
    line-height: 110%;
}
.w-pc-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.text-wrapper>p {
    font-size: 0.9vw;
}



/* mobile */
.w-mob-global-submenu-detail {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.w-mob-global-submenu-detail.wrapper {
    position: relative;
}

.w-mob-global-submenu-detail.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-mob-global-submenu-detail.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-mob-global-submenu-detail.wrapper>.background>.frame>img {
    width: 100%;
}

.w-mob-global-submenu-detail.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-mob-global-submenu-detail.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    color: #2c2c34;
}

.w-mob-global-submenu-detail.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    box-sizing: border-box;
    /* background-color: blue; */
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.header {
    height: 50%;
    width: 100%;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.header>.sub {
    width: 100%;
    box-sizing: border-box;
    
    
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.w-mob-global-submenu-detail.wrapper>.content.display>.section.header>.sub.text-wrapper{
    padding-inline: 5vw;
    height: 30%;
    /* border: 1px solid yellow; */
}
.w-mob-global-submenu-detail.wrapper>.content.display>.section.header>.sub.text-wrapper>h2 {
    font-size: 6vw;

}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.header>.sub.text-wrapper>p {
    font-size:3vw;
    width: 90%;
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.header>.sub.frame {
    /* background-color: red; */
    overflow: hidden;
    border-radius: 20px;
    width: 90%;
    height: 60%;
    /* border: 1px solid blue; */
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.header>.sub.frame>img {
    width: 109%;
}



.w-mob-global-submenu-detail.wrapper>.content.display>.section.cardholder {
    height: 35%;
    width: 100%;
    /* background-color: yellow; */
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start; */

    display: flex;
    width: 100%;
    overflow-x: auto;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    align-items: center;
    padding-inline: 3vw;
    margin-top: 4vh;
    /* background-color: yellow; */
    box-sizing: border-box;
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card {
    /* box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.297);
    border-radius: 20px;
    margin-right: 2vw;
    height: 90%;
    width: 23%;
    padding-inline: 1vw;
    padding-block: 1vw; */

    min-width: 45vw;
    margin-right: 20px;
    height: 90%;
    width: 300px;
    border-radius: 8px;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.297);
    padding-inline: 5vw;
    /* border: 1px solid red; */
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub {
    width: 100%;
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.frame {
    /* background-color: red; */
    height: 40%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    overflow: hidden;
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.frame>img {
    height: 80%;
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.text-wrapper {
    height: 60%;
    padding-inline: 1vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-top: 1.5vw;
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.text-wrapper>h2 {
    font-size:3vw;
    margin-top: 2.5vw;
    margin-bottom: 1.5vw;
    line-height: 110%;
}

.w-mob-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.text-wrapper>p {
    font-size: 2.8vw;
}


/* tab device */
.w-tab-global-submenu-detail {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.w-tab-global-submenu-detail.wrapper {
    position: relative;
}

.w-tab-global-submenu-detail.wrapper>.background {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(95, 199, 255); */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.w-tab-global-submenu-detail.wrapper>.background>.frame {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.w-tab-global-submenu-detail.wrapper>.background>.frame>img {
    width: 100%;
}

.w-tab-global-submenu-detail.wrapper>.shield {
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: salmon; */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
}

.w-tab-global-submenu-detail.wrapper>.content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    z-index: 102;
    color: #2c2c34;
}

.w-tab-global-submenu-detail.wrapper>.content.display {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.header {
    height: 50%;
    width: 100%;
    /* background-color: yellow; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.header>.sub {
    width: 50%;
    box-sizing: border-box;
    /* border: 1px solid red; */
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.header>.sub.text-wrapper>h2 {
    font-size: 3.8vw;

}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.header>.sub.text-wrapper>p {
    font-size: 1.5vw;
    width: 90%;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.header>.sub.frame {
    /* background-color: red; */
    overflow: hidden;
    border-radius: 20px;
    width: 44%;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.header>.sub.frame>img {
    width: 100%;
}



.w-tab-global-submenu-detail.wrapper>.content.display>.section.cardholder {
    height: 50%;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    box-sizing: border-box;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card {
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.297);
    border-radius: 20px;
    margin-right: 2vw;
    height: 90%;
    width: 23%;
    /* background-color: red; */
    padding-inline: 1vw;
    padding-block: 1vw;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub {
    width: 100%;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.frame {
    /* background-color: red; */
    height: 40%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    overflow: hidden;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.frame>img {
    height: 80%;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.text-wrapper {
    height: 60%;
    padding-inline: 1vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-top: 1.5vw;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.text-wrapper>h2 {
    font-size: 1.7vw;
    margin-bottom: 0.3vw;
    line-height: 110%;
}

.w-tab-global-submenu-detail.wrapper>.content.display>.section.cardholder>.card>.sub.text-wrapper>p {
    font-size: 0.9vw;
}